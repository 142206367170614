import Axios from "axios";
import { API_URL } from "./types";
// Add a request interceptor
const axiosConfig = Axios.create({
  baseURL: API_URL,
  // timeout: 10000,
  // withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});
Axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.common["AUTH-TOKEN"] = token;
  return config;
});

Axios.interceptors.response.use(undefined, (err) => {
  const error = err.response;
  // if error is 401
  console.log(err);

  if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
  }
  return error.config;
});

axiosConfig.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.common["AUTH-TOKEN"] = token;
  return config;
});

axiosConfig.interceptors.response.use(undefined, (err) => {
  const error = err.response;
  // if error is 401
  console.log(err);

  if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
  }
  return error.config;
});

export const axiosApi = axiosConfig;
