import React from "react";

import FormHeader from "../Item/FormHeader";

import InputAreaControlled from "../ui/InputAreaControlled";

function ManyItemsFormFields() {
  return (
    <div id={"capture"}>
      <div className="container-border p-4">
        <FormHeader />
      </div>
      <div className="container-border p-4 mt-2">
        <h3>Observaciones Generales</h3>
        <InputAreaControlled name="comments" />
      </div>
    </div>
  );
}
export default ManyItemsFormFields;
