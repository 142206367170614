/**
 * A callback function type that receives data of any type.
 * @callback EventCallback
 * @param {*} data - The data passed when the event is emitted.
 */

/**
 * Class to handle event subscriptions and emissions.
 */
class EventEmitter {
  constructor() {
    // Object to store events and their associated callback arrays
    this.events = {};
  }

  /**
   * Subscribes a callback function to a specific event.
   * @param {string} event - The name of the event to subscribe to.
   * @param {EventCallback} callback - The function to call when the event is emitted.
   */
  subscribe(event, callback) {
    // If the event doesn't exist, initialize it as an empty array
    if (!this.events[event]) {
      this.events[event] = [];
    }
    // Add the callback to the event's callback array
    this.events[event].push(callback);
  }

  /**
   * Unsubscribes a callback function from a specific event.
   * @param {string} event - The name of the event to unsubscribe from.
   * @param {EventCallback} callback - The callback function to remove from the event.
   */
  unsubscribe(event, callback) {
    // If the event doesn't exist, do nothing
    if (!this.events[event]) return;

    // Remove the specified callback from the event's callback array
    this.events[event] = this.events[event].filter((cb) => cb !== callback);
  }

  /**
   * Emits an event, calling all subscribed callbacks with the provided data.
   * @param {string} event - The name of the event to emit.
   * @param {*} [data] - Optional data to pass to each callback function.
   */
  emit(event, data) {
    console.log(event, data); // Optional: Logs the event and data to the console

    // If there are no callbacks for the event, do nothing
    if (!this.events[event]) return;

    // Call each callback in the event's callback array with the provided data
    this.events[event].forEach((callback) => callback(data));
  }
}

// Create an instance of EventEmitter
const eventEmitter = new EventEmitter();
export default eventEmitter;
