import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Spinner,
  Button,
  Label,
  Input,
  Alert,
  Row,
  Col,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Loading from "../Loading/Loading";
import { createCategoryType } from "../../redux/actions/provider";

function CategoryTypeModal(props) {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.provider.createCategoryType);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [modal, setModal] = useState(props.modal);
  const [modalCategory, setModalCategory] = useState(props.modalCategory);
  const [disabled, setdisabled] = useState(props.disabled);
  let history = useHistory();

  useEffect(() => {
    if (response) {
      setLoading(false);
    }
  }, [response]);

  const toggle = (name) => {
    if (typeof name === typeof "") {
      setModalCategory(name);
    }
    setModal(!modal);
  };

  const saveModal = () => {
    dispatch(createCategoryType(modalCategory.category_value, name));
    setLoading(true);
    setModal(!modal);
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <Button
        className="float-right md-2"
        color="success"
        onClick={() => toggle(modalCategory)}
        disabled={disabled}
      >
        Crear Subcategoria
        <i className="fa fa-plus pl-3"></i>
      </Button>
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalCategory.category_name}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="obs">Nombre del tipo</Label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Nombre..."
              onChange={(e) => setName(e.target?.value)}
              value={name}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveModal}>
            Guardar
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CategoryTypeModal;
