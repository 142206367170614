import { useMutation } from "react-query";
import { axiosApi } from "../../redux/actions";

const useCreateResource = ({ resource }) => {
  const mutation = useMutation({
    mutationFn: async (value) => {
      return await axiosApi.post(resource, value).then((res) => res.data);
    },
  });
  return mutation;
};

export default useCreateResource;
