import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Label,
  Input,
  Alert,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardFooter,
} from "reactstrap";
import { getProvider } from "../../redux/actions/provider";
import {
  getForms,
  validItem,
  getItem,
  cleanValidItem,
} from "../../redux/actions/forms";
import noImage from "../../assets/img/noimage.png";
import Loading from "../../components/Loading/Loading";

function GridItems() {
  const dispatch = useDispatch();
  let history = useHistory();
  const responseV = useSelector((state) => state.forms.valid);
  const item = useSelector((state) => state.forms.getItem);

  const response = useSelector((state) => state.forms.forms);
  const [loading, setLoading] = useState(true);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [filterNI, setfilterNI] = useState("");
  const [mandante, setfilterMandante] = useState("");
  const [filterStatus, setfilterStatus] = useState("");
  const [filterPatente, setfilterPatente] = useState("");
  const [page, setPage] = useState(0);
  const providers = useSelector((state) => state.provider.providers);
  const [update, setUpdate] = useState(false);
  const statusList = [
    { name: "Liberado" },
    { name: "Vendido" },
    { name: "Devuelto" },
    { name: "No Liberado" },
  ];

  useEffect(() => {
    document.title = "BGL - Bienes";
    dispatch(getProvider());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (history.location.filter === undefined) {
      dispatch(getForms({ view_type: "items", start: "0", end: "30" }));
    } else if (loading === true) {
      let mandante = history.location.filter;
      setfilterMandante(mandante);
      dispatch(
        getForms({
          view_type: "items",
          start: "0",
          end: "30",
          provider: mandante,
        })
      );
    }
  }, [history.location.state]);

  useEffect(() => {
    if (responseV) {
      if (responseV.data) {
        dispatch(getItem(responseV.data.numerointerno));
        setUpdate(true);
      } else {
        setAlertTitle("El bien que esta buscando no existe");
        setAlertType("danger");
        setShowAlert(true);
        setLoading(false);
      }
    }
  }, [responseV]);

  useEffect(() => {
    if (item) {
      if (item.status === "success" && update) {
        setUpdate(false);
        dispatch(cleanValidItem());
        history.push({
          pathname: "/item-admin",
          state: item.data.data,
        });
      }
    }
  }, [item]);

  useEffect(() => {
    if (response) {
      if (response.status === "success") {
        setLoading(false);
      }
      setAlertTitle(response.status);
      setAlertType(response.status);
      setAlertMessage(response.message);
    }
  }, [response]);

  useEffect(() => {
    if (providers) {
      if (providers.message === "Providers") {
      }
    }
  }, [providers]);

  const onDismiss = () => setShowAlert(false);
  const goToItem = (item) => {
    console.log(item);
    history.push({
      pathname: "/item-admin",
      state: item,
    });
  };

  const stateColor = (state) => {
    if (state == "Liberado") {
      return <span className="badge badge-info">ESTADO: LIBERADO</span>;
    } else if (state == "Vendido") {
      return <span className="badge badge-success">ESTADO: VENDIDO</span>;
    } else if (state == "Devuelto") {
      return (
        <span style={{ color: "white" }} className="badge badge-warning">
          ESTADO: DEVUELTO
        </span>
      );
    } else {
      return <span className="badge badge-danger">ESTADO: NO LIBERADO</span>;
    }
  };

  const search = (searchNI, searchPatente) => {
    dispatch(validItem(searchNI, searchPatente));
    setLoading(true);
  };

  const nextPage = () => {
    let start = (page + 1) * 30;
    setPage(page + 1);
    let end = start + 30;
    let params = { view_type: "items", start: start, end: end };
    if (filterStatus !== "") {
      params.status = filterStatus;
    }
    if (mandante !== "") {
      params.provider = mandante;
    }
    dispatch(getForms(params));
    window.scrollTo(0, 0);
    setLoading(true);
  };

  const previousPage = () => {
    let start = (page - 1) * 30;
    setPage(page - 1);
    let end = start + 30;
    let params = { view_type: "items", start: start, end: end };
    if (filterStatus !== "") {
      params.status = filterStatus;
    }
    if (mandante !== "") {
      params.provider = mandante;
    }
    dispatch(getForms(params));
    window.scrollTo(0, 0);
    setLoading(true);
  };

  const onChangeMandante = (e) => {
    setfilterMandante(e.target?.value);
    let params = { view_type: "items", start: 0, end: 30 };
    if (filterStatus !== "") {
      params.status = filterStatus;
    }
    params.provider = e.target?.value;
    dispatch(getForms(params));
    setPage(0);
  };

  const onChangeStatus = (e) => {
    setfilterStatus(e.target?.value);
    let params = { view_type: "items", start: 0, end: 30 };
    params.status = e.target?.value;
    if (mandante !== "") {
      params.provider = mandante;
    }
    dispatch(getForms(params));
    setPage(0);
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <Container>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>
      <Container>
        <h2>Filtros</h2>
        <Form onSubmit={(e) => search(filterNI, filterPatente)}>
          <Row>
            <Col>
              <Input
                className="col-md mt-2"
                placeholder="NUMERO INTERNO"
                type="text"
                value={filterNI}
                onChange={(e) => {
                  setfilterNI(e.target?.value);
                }}
              />
            </Col>
            <Col>
              <Input
                className="col-md mt-2"
                placeholder="PATENTE"
                type="text"
                value={filterPatente}
                onChange={(e) => setfilterPatente(e.target?.value)}
              />
            </Col>
            <Col>
              <Button className="col-md">Buscar</Button>
            </Col>
          </Row>
        </Form>

        <h6>Mandantes</h6>
        <Input
          type="select"
          onChange={(e) => {
            onChangeMandante(e);
          }}
          value={mandante}
        >
          <option value={""}></option>
          {providers &&
            providers.data.map((provider, index) => (
              <option key={index} value={provider.name}>
                {provider.name}
              </option>
            ))}
        </Input>
        <h6 className="mt-3">Estado</h6>
        <Input
          type="select"
          onChange={(e) => {
            onChangeStatus(e);
          }}
          value={filterStatus}
        >
          <option value={""}></option>
          {statusList &&
            statusList.map((stat, index) => (
              <option key={index} value={stat.name}>
                {stat.name}
              </option>
            ))}
        </Input>

        <Row className="mt-3">
          {response &&
            response.data.map((item, index) => {
              return typeof item.data.status === "object" ? (
                <Col
                  className="mb-2"
                  xs="12"
                  sm="6"
                  md="4"
                  lg="4"
                  key={item.id}
                >
                  <Card
                    className="container-border"
                    style={{ height: "100%", width: "100%" }}
                    key={item.id}
                    onClick={(e) => goToItem(item.data)}
                  >
                    <CardTitle tag="h5">
                      {item.data.head.numerointerno
                        ? item.data.head.numerointerno?.value
                        : ""}{" "}
                      {item.data.head.ano ? item.data.head.ano?.value : ""}{" "}
                      {item.data.head.marca ? item.data.head.marca?.value : ""}{" "}
                      {item.data.head.modelo
                        ? item.data.head.modelo?.value
                        : ""}
                    </CardTitle>
                    <img
                      style={{ width: "100%" }}
                      src={
                        item.data?.img?.[0] ? item.data?.img?.[0].data : noImage
                      }
                      alt="FirstImage"
                    />
                    <CardBody style={{ height: "70%" }}>
                      <CardText className="float-right">
                        {item.data.web && item.data.web.show ? (
                          <span className="badge badge-success">WEB</span>
                        ) : (
                          <span className="badge badge-danger">WEB</span>
                        )}
                      </CardText>
                      <CardText>
                        {typeof item.data.status === "object"
                          ? Object.values(item.data.status).reduceRight(
                              (result, value, index, array) =>
                                result || stateColor(array[index].status),
                              null
                            )
                          : stateColor(item.data.status)}
                      </CardText>

                      <CardText>
                        {item.data.head.categoria?.value} -{" "}
                        {item.data.head.tipo?.value}
                      </CardText>
                      <CardText>
                        {" "}
                        {item.data.price
                          ? Object.values(item.data.price).pop().price +
                            " " +
                            Object.values(item.data.price).pop().iva +
                            " + " +
                            Object.values(item.data.price).pop().comission +
                            "% DE COMISION"
                          : "NO LIBERADO PARA LA VENTA"}
                      </CardText>
                    </CardBody>
                    <CardFooter>
                      <Button
                        color="success"
                        onClick={() => {
                          goToItem(item.data);
                        }}
                      >
                        Ver Mas
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-box-arrow-right ml-2"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                          />
                        </svg>
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              ) : (
                <Col
                  className="mb-2"
                  xs="12"
                  sm="6"
                  md="4"
                  lg="4"
                  key={item.id}
                >
                  <Card
                    className="container-border"
                    style={{ height: "100%", width: "100%" }}
                    key={item.id}
                    onClick={(e) => goToItem(item.data)}
                  >
                    <CardTitle tag="h5">
                      {item.data.head.numerointerno
                        ? item.data.head.numerointerno?.value
                        : ""}{" "}
                      {item.data.head.ano ? item.data.head.ano?.value : ""}{" "}
                      {item.data.head.marca ? item.data.head.marca?.value : ""}{" "}
                      {item.data.head.modelo
                        ? item.data.head.modelo?.value
                        : ""}
                    </CardTitle>
                    <img
                      style={{ width: "100%" }}
                      src={
                        item.data?.img?.[0] ? item.data?.img?.[0].data : noImage
                      }
                      alt="FirstImage"
                    />
                    <CardBody style={{ height: "70%" }}>
                      <CardText className="float-right">
                        {item.data.web && item.data.web.show ? (
                          <span className="badge badge-success">WEB</span>
                        ) : (
                          <span className="badge badge-danger">WEB</span>
                        )}
                      </CardText>
                      <CardText>
                        {typeof item.data.status === "object"
                          ? Object.values(item.data.status).reduceRight(
                              (result, value, index, array) =>
                                result || stateColor(array[index].status),
                              null
                            )
                          : stateColor(item.data.status)}
                      </CardText>

                      <CardText>
                        {item.data.head.categoria?.value} -{" "}
                        {item.data.head.tipo?.value}
                      </CardText>

                      <CardText>
                        {" "}
                        {item.data.price
                          ? item.data.price +
                            " " +
                            item.data.iva +
                            " + " +
                            item.data.comission +
                            "% DE COMISION"
                          : "NO LIBERADO PARA LA VENTA"}
                      </CardText>
                    </CardBody>
                    <CardFooter>
                      <Button
                        color="success"
                        onClick={() => {
                          goToItem(item.data);
                        }}
                      >
                        Ver Mas
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-box-arrow-right ml-2"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                          />
                        </svg>
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              );
            })}
        </Row>
        <Container>
          <Button
            className="float-right m-3"
            onClick={() => {
              nextPage();
            }}
          >
            Siguiente
          </Button>
          <Button
            disabled={page <= 0 ? true : false}
            className="m-3"
            onClick={() => {
              previousPage();
            }}
          >
            Anterior
          </Button>
        </Container>
      </Container>
    </div>
  );
}
export default GridItems;
