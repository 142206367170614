import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Spinner, Table, Button } from "reactstrap";
import { clientList, deleteUser } from "../../redux/actions/user";
import XTable from "../../components/Table/Table";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";

function ClientList() {
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.lang.lang);
  const response = useSelector((state) => state.user);
  //const responseDelete = useSelector(state => state.user.userDelete);
  const [loading, setLoading] = useState(true);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const history = useHistory();

  useEffect(() => {
    document.title = "BGL - Lista Clientes";
    dispatch(clientList());
  }, []);

  useEffect(() => {
    if (response) {
      console.log("Response: ", response);
      if (response.clientList && response.clientList.status === "all clients") {
        console.log("Response: ", response);
        setLoading(false);
      } else if (response.clientList && response.clientList.status === "success") {
        console.log("pregunta");
        dispatch(clientList());
      }
    }
  }, [response]);

  const goToItem = (item) => {
    history.push({
      pathname: "/client-create",
      state: { data: item },
    });
  };
  const deleteItem = (item) => {
    setLoading(true);
    dispatch(deleteUser(item));
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container className='container-border p-3'>
        <h3 className='float-left'>Lista de Clientes</h3>
        <Button
          color='success'
          className='float-right'
          onClick={() => history.push({ pathname: "/client-create" })}
        >
          Crear Cliente
          <i className='fa fa-plus ml-3'></i>
        </Button>
        <div>
           <Table hover >
            <thead>
              <tr>
                <th>Email</th>
                <th>Nombre</th>
                <th>Telefono</th>
                <th>Rut</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {response.clientList.data.map((row, index) => {
                console.log("Response: ", response);
                return (
                  <tr key={index}>
                    <td style={{width: "100px"}}>{row.email}</td>
                    <td style={{width: "100px"}}>{row.name}</td>
                    <td style={{width: "100px"}}>{row.phone}</td>
                    <td style={{width: "100px"}}>{row.rut}</td>
                    <td style={{width: "100px"}}>
                      {
                        <Button
                          onClick={() => {
                            goToItem(row);
                          }}
                        >
                          <i className='fa fa-eye'></i>
                        </Button>
                      }
                      {
                        <ConfirmationModal
                          btnClass='btn btn-danger'
                          iconClass='fa fa-trash'
                          title='Eliminar Usuario'
                          message='Estas seguro que quieres eliminar a este usuario?'
                          action={deleteItem}
                          argument={row}
                        ></ConfirmationModal>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
    </>
  );
}
export default ClientList;
