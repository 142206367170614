import React, { useEffect } from "react";
import usePageTitle from "../../hooks/usePageTitle";
import useResource from "../../api/services/useResource";
import Loading from "../../components/Loading/Loading";
import { Button, Container } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import CarFormFields from "../../components/Forms/CarFormFields";
import TruckFormFields from "../../components/Forms/TruckFormFields";
import MachineryFormFields from "../../components/Forms/MachineryFormFields";
import ManyItemsFormFields from "../../components/Forms/ManyItemsFormFields";
import useCreateResource from "../../api/services/useCreateResource";
import useUpdateResource from "../../api/services/useUpdateResource";
import { confirmAlert } from "../../components/ui/ConfirmAlert";
import { useHistory } from "react-router-dom";
import ImageGalleryForm from "../../components/Forms/ImageGalleryForm";

const formTypes = {
  car: <CarFormFields />,
  truck: <TruckFormFields />,
  machinery: <MachineryFormFields />,
  many: <ManyItemsFormFields />,
};

const EditItemForm = (props) => {
  usePageTitle("BGL - Editar bien");
  const history = useHistory();
  const { mutate: updateForm } = useCreateResource({ resource: "/item/form" });
  const { mutate: updateAndAprove } = useUpdateResource({
    resource: "/item/form",
  });

  const { numerointerno } = props.match.params;
  const { data: itemData, isLoading } = useResource({
    resource: "/item/form",
    params: { numerointerno },
  });

  const formMethods = useForm({});
  const { reset, getValues } = formMethods;

  const item = itemData?.data || {};

  useEffect(() => {
    if (item?.data) {
      reset({ ...item.data });
    }
  }, [item]);

  const onSubmit = formMethods.handleSubmit((formData) => {
    confirmAlert({
      title: "Guardar",
      question: "¿Estas seguro que quieres guardas este bien?",
      confirmText: "Confirmar",
      onConfirm: async () => {
        updateForm(
          { data: formData },
          {
            onSuccess: (data) => {
              history.push(`/item-photo/${data.numerointerno}`);
            },
          }
        );
      },
    });
  });

  const approveForm = () => {
    const formData = getValues();
    confirmAlert({
      title: "Guardar",
      question: "¿Estas seguro que quieres aprobar este bien?",
      confirmText: "Confirmar",
      onConfirm: async () => {
        updateAndAprove(
          { data: formData },
          {
            onSuccess: (data) => {
              history.push(`/item-photo/${data.numerointerno}`);
            },
          }
        );
      },
    });
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Container>
      <div>
        <h2 className="d-flex justify-content-center mb-2">
          NUMERO INTERNO: {numerointerno}
        </h2>
      </div>
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>
          <div className="d-flex justify-content-between">
            <div>
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
            <div className="d-flex">
              <Button color="primary" onClick={approveForm}>
                Aprobar
              </Button>
              <Button onClick={() => history.goBack()} color="info">
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              </Button>
            </div>
          </div>

          {formTypes[item?.data?.type]}
          <div></div>
          <div className="container-border p-4 mt-2">
            <ImageGalleryForm images={item?.data?.img ?? []} />
          </div>
        </form>
      </FormProvider>
    </Container>
  );
};

export default EditItemForm;
