import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Button,
  Label,
  Input,
  Alert,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import ItemInfo from "../../components/Item/ItemInfo";
import ModalItemState from "../../components/Modal/ModalItemState";
import noImage from "../../assets/img/noimage.png";
import ModalWebPage from "../../components/Modal/ModalWebPage";
import { API_URL } from "../../redux/actions/types";
import Loading from "../../components/Loading/Loading";
import { getItem, feature } from "../../redux/actions/forms";
import { getLastKey } from "../../utils/utils";

function Item() {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.forms.change);
  const responseWeb = useSelector((state) => state.forms.web);
  const responseGet = useSelector((state) => state.forms.getItem);
  const responseFeature = useSelector((state) => state.forms.feature);
  const [loading, setLoading] = useState(true);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState([]);
  let history = useHistory();
  const [loadingC, setLoadingC] = useState(true);
  const isAdmin = localStorage.getItem("role");

  const [modalType, setModalType] = useState("liberar");

  const fetchData = (data) => {
    dispatch(getItem(data.head.numerointerno?.value));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "BGL - Bienes";
  }, []);

  useEffect(() => {
    if (response && loadingC === false) {
      setData(response.data);
      let state = response.data;
      history.replace({ ...history.location, state });
      setLoading(false);
    }
  }, [response, loadingC]);

  useEffect(() => {
    if (responseGet && loadingC === false) {
      setData(responseGet.data.data);
      let state = responseGet.data.data;
      history.replace({ ...history.location, state });
      setLoading(false);
    }
  }, [responseGet, loadingC]);

  useEffect(() => {
    if (responseWeb && loadingC === false) {
      setData(responseWeb.data.data);
      let state = responseWeb.data.data;
      history.replace({ ...history.location, state });
      setLoading(false);
    }
  }, [responseWeb, loadingC]);

  useEffect(() => {
    if (responseFeature && loadingC === false) {
      console.log(responseFeature);
      setData(responseFeature.data);
      let state = responseFeature.data;
      history.replace({ ...history.location, state });
      setLoading(false);
    }
  }, [responseFeature, loadingC]);

  useEffect(() => {
    if (history.location.state === undefined) {
      return;
    } else if (loadingC === true) {
      setLoadingC(false);
      let data = history.location.state;
      dispatch(getItem(data.head.numerointerno?.value));
    }
  }, [history.location.state, loadingC]);

  const changeFeatured = (numerointerno) => {
    setLoading(true);
    dispatch(feature(numerointerno));
  };

  const goToItem = () => {
    history.push({
      pathname: "/item-client",
      state: { id: data.head.numerointerno?.value },
    });
  };

  const goToImage = (numerointerno) => {
    history.push(`/item-image/${numerointerno}`);
  };
  const goToEdit = (numerointerno) => {
    console.log(numerointerno);
    history.push(`/edit-item/${numerointerno}`);
  };
  const goToQuotation = () => {
    if (typeof data.price === "object" && data.price !== null) {
      const keys = Object.keys(data.price);
      const maxKey = keys.reduce((a, b) => (a > b ? a : b), keys[0]);
      const price = data.price[maxKey].price;
      const iva = data.price[maxKey].iva;
      const comission = data.price[maxKey].comission;
      const numerointerno = data.head.numerointerno?.value;
      const state = { data: { numerointerno, comission, iva, price } };
      history.push({
        pathname: "/item-quotation",
        state,
      });
    } else {
      const { comission, iva, price } = data;
      const numerointerno = data.head.numerointerno?.value;
      const state = { data: { numerointerno, comission, iva, price } };
      history.push({
        pathname: "/item-quotation",
        state,
      });
    }
  };
  const goToOffer = () => {
    history.push({
      pathname: "/item-offer",
      state: { data: { numerointerno: data.head.numerointerno?.value } },
    });
  };
  const goToFiles = () => {
    history.push({
      pathname: "/item-file",
      state: data,
    });
  };

  const onDismiss = () => setShowAlert(false);
  console.log(
    data.price !== undefined
      ? typeof data.price === "object"
        ? false
        : false
      : true
  );
  console.log(data.price);

  return loading ? (
    <Loading />
  ) : (
    <Container className="container-border p-3" style={{ margin: "auto" }}>
      <Container>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>
      <Button
        color=""
        className="float-right"
        onClick={() => {
          history.goBack();
        }}
      >
        <i className="fa fa-chevron-left" aria-hidden="true"></i>
      </Button>
      <Container>
        <h1>
          {data.head.ano ? data.head.ano?.value : ""}{" "}
          {data.head.marca ? data.head.marca?.value : ""}{" "}
          {data.head.modelo ? data.head.modelo?.value : ""}
        </h1>
        <Row>
          <Col>
            <table>
              <tbody>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td>
                    <h3>Numero Interno:</h3>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <h3>
                      {data.head.numerointerno?.value
                        ? data.head.numerointerno?.value
                        : "Sin numero interno"}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h3>Mandante:</h3>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <h3>{data.head.mandante?.value}</h3>
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td>
                    <h3>Patente:</h3>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <h3>
                      {data.head.patente
                        ? data.head.patente?.value
                        : "sin patente"}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h3>Estado:</h3>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <h3>
                      {data.status
                        ? typeof data.status === "object"
                          ? data.status[getLastKey(data.status)].status
                          : data.status
                        : "NO LIBERADO"}
                    </h3>
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td>
                    <h3>Precio:</h3>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <h3>
                      {typeof data.price === "object"
                        ? data.price[getLastKey(data.price)].price !== ""
                          ? data.price[getLastKey(data.price)].price +
                            " " +
                            data.price[getLastKey(data.price)].iva +
                            " + " +
                            data.price[getLastKey(data.price)].comission +
                            "% DE COMISION"
                          : "NO LIBERADO PARA LA VENTA"
                        : data.price
                        ? data.price +
                          " " +
                          (data.iva ? data.iva : "") +
                          (data.comission
                            ? " + " + data.comission + "% DE COMISION"
                            : "")
                        : "NO LIBERADO PARA LA VENTA"}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h3>Pagina Web:</h3>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <h3>{data.web ? (data.web.show ? "Si" : "No") : "No"}</h3>
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td>
                    <FormGroup check>
                      <Label check>
                        <Input
                          className="mt-3"
                          type="radio"
                          disabled={data.web ? false : true}
                          onClick={() =>
                            changeFeatured(data.head.numerointerno?.value)
                          }
                          checked={data.featured ? data.featured : false}
                          name="radio1"
                        />
                        <h3>Destacado</h3>
                      </Label>
                    </FormGroup>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col>
            <img
              src={data?.img?.[0] ? data?.img?.[0].data : noImage}
              alt="FirstImage"
              style={{ width: "100%" }}
            />
            <Button
              onClick={() => goToImage(data.head.numerointerno?.value)}
              style={{ width: "100%" }}
            >
              Ver Imagenes
            </Button>
          </Col>
        </Row>
      </Container>
      <Container>
        <h2 className="mb-1">Estados</h2>
        <Row>
          <Col>
            <ModalItemState
              data={data}
              modalType={"Liberado"}
              disabled={
                data.status
                  ? typeof data.status === "object"
                    ? data.status[getLastKey(data.status)].status === "Liberado"
                    : data.status === "Liberado"
                  : false
              }
            />
          </Col>
          <Col>
            <ModalItemState data={data} modalType={"No Liberado"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ModalItemState data={data} modalType={"Vendido"} />
          </Col>
          <Col>
            <ModalItemState data={data} modalType={"Devuelto"} />
          </Col>
        </Row>
        <h2 className="mb-1">Acciones</h2>
        <Row>
          <Col>
            <Button
              style={{ width: "100%" }}
              color="info"
              onClick={() => goToItem()}
            >
              Interesados
            </Button>
          </Col>
          <Col>
            <Button
              style={{ width: "100%" }}
              color="info"
              onClick={() => goToEdit(data.head.numerointerno?.value)}
              disabled={isAdmin === "admin" ? false : true}
            >
              Editar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              style={{ width: "100%" }}
              color="secondary"
              onClick={() => goToQuotation(data)}
            >
              Cotizar
            </Button>
          </Col>
          <Col>
            <Button
              style={{ width: "100%" }}
              color="secondary"
              onClick={() => goToOffer(data)}
            >
              Ofertar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <a
              style={{ width: "100%" }}
              className="btn btn-primary"
              target="_blank"
              href={
                API_URL +
                "/item/form/pdf?pdf_type=recep&numerointerno=" +
                data.head.numerointerno?.value
              }
            >
              ACTA DE RECEPCION
            </a>
          </Col>
          <Col>
            <a
              style={{ width: "100%" }}
              disabled={
                data.status
                  ? typeof data.status === "object"
                    ? !(
                        data.status[getLastKey(data.status)].status ===
                          "Vendido" ||
                        data.status[getLastKey(data.status)].status ===
                          "Devuelto"
                      )
                    : !(data.status === "Vendido" || data.status === "Devuelto")
                  : true
              }
              className="btn btn-primary"
              target="_blank"
              href={
                API_URL +
                "/item/form/pdf?pdf_type=exit&numerointerno=" +
                data.head.numerointerno?.value
              }
            >
              ACTA DE ENTREGA
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <ModalItemState
              data={data}
              modalType={"Cambiar"}
              disabled={
                data.price !== undefined
                  ? typeof data.price === "object"
                    ? false
                    : false
                  : true
              }
            />
          </Col>
          <Col>
            <Button
              className="btn btn-warning"
              onClick={() => goToFiles(data)}
              style={{ width: "100%" }}
            >
              Ver Archivos
            </Button>
          </Col>
        </Row>
        <ModalWebPage data={data} />
        <ItemInfo data={data} />
      </Container>
    </Container>
  );
}

export default Item;
