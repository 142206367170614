
export const API_URL = process.env.REACT_APP_ENV_API_URL;


export const types = {
  changeLanguage: '[Lang] Change',
  signIn: '[API] SignIn',
  signOut: '[API] SignOut',
  userCreate: '[API] Create user',
  userList: '[API] User List',
  userUpdate: '[API] Update user',
  deleteUser: '[API] Delete user',
  roles: '[API] Roles',
  createdForm: '[API] createForm',
  acceptForm: '[API] acceptForm',
  setForms: '[API] setForms',
  createProvider: '[API] create Provider',
  getProvider: '[API] get Providers',
  deleteProvider: '[API] delete Provider',
  changeFormState: '[API] change Form State',
  getCategories: '[API] get Categories',
  createCategory: '[API] create category',
  createCategoryType: '[API] create category type',
  createQuotation: '[API] create Quotation',
  updateQuotation: '[API] update Quotation',
  getQuotation: '[API] get Quotation',
  createOffer: '[API] create Offer',
  updateOffer: '[API] update Offer',
  getOffer: '[API] get Offer',
  getItem: '[API] get Item',
  validItem: '[API] valid Item',
  clientList: '[API] client list',
  createClient: '[API] create client',
  clientUpdate: '[API] update client',
  changeImageStateForm: '[API] chgane state image',
  formFile: '[API] save file item',
  webPageForm: '[API] show in WebPage',
  formPhoto: '[API] upload Photo',
  getStats: '[API] get stats',
  createReport: '[API] create reports',
  feature: '[API] feature',
  notify: '[API] Notify clients',
  getItemStorage: '[API] item storages',
  selectFirst: '[API] first image item',
  exportQuotation: '[API] export Quotation',
  exportOffer: '[API] export Offer',
  



};
