import { useEffect } from "react";
import eventEmitter from "./EventEmitter";

/**
 * Custom hook to subscribe to one or more events and handle them with a callback.
 * Automatically unsubscribes when the component is unmounted or dependencies change.
 *
 * @param {function} callback - The function to execute when the event is emitted.
 * @param {string|string[]} events - The event name or an array of event names to listen for.
 */
const useEventListener = (callback, events) => {
  useEffect(() => {
    // Ensure events is always an array
    const eventArray = Array.isArray(events) ? events : [events];

    // Subscribe to each event in the array
    eventArray.forEach((event) => {
      eventEmitter.subscribe(event, callback);
    });

    // Cleanup function to unsubscribe when component unmounts or dependencies change
    return () => {
      eventArray.forEach((event) => {
        eventEmitter.unsubscribe(event, callback);
      });
    };
  }, [events, callback]);
};

export default useEventListener;
