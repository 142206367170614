import React, { useEffect, useState } from "react";
import Logo from "../../assets/img/logo.jpeg";
import { Container, Col, Row, Progress } from "reactstrap";
import { Animated } from "react-animated-css";

function Loading(props) {
  const [visible, setvisible] = useState(true);
  const [value, setvalue] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => changeVisible(!visible), 700);
    return () => clearTimeout(timer);
  });

  const changeVisible = (vis) => {
    setvisible(vis);
    if (value == 0) {
      setvalue(100);
    } else {
      setvalue(0);
    }
  };

  return (
    <Container style={{ width: "300px" }} className="overflow-hidden  p-3">
      <Animated
        animationIn="slideInLeft"
        animationOut="slideOutRight"
        animationInDuratiion={700}
        animationOutDuration={700}
        isVisible={visible}
      >
        <div>
          <img x="0" y="0" height="100" className="" src={Logo}></img>
        </div>
      </Animated>
    
    </Container>
  );
}
export default Loading;
