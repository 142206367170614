import React, { useEffect, useState } from "react";
import {
  Container,
  Spinner,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
  Row,
  Col,
} from "reactstrap";

function ItemType(props) {
  return (
    <Container>
      <Row>
        <Col lg="2"></Col>
        <Col lg="8">
          <Button
            className="col-12"
            onClick={() => {
              props.setValue(1);
            }}
          >
            Vehículos Livianos
          </Button>
          <Button
            className="col-12"
            onClick={() => {
              props.setValue(2);
            }}
          >
            Camiones
          </Button>
          <Button
            className="col-12"
            onClick={() => {
              props.setValue(3);
            }}
          >
            Maquinaria
          </Button>
          <Button
            className="col-12"
            onClick={() => {
              props.setValue(4);
            }}
          >
            Otros
          </Button>
        </Col>
        <Col lg="2"></Col>
      </Row>
    </Container>
  );
}
export default ItemType;
