import React, { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Button } from "reactstrap";

const ImageGalleryForm = ({ name = "img" }) => {
  const { watch, setValue } = useFormContext();
  const galleryRef = useRef(null);
  const currentSlide = useRef(0);
  const images = watch(name) ?? [];
  const onDelete = () => {
    const targetImage = images[currentSlide.current];
    if (targetImage) {
      const filteredImages = images.filter(
        (image) => image.data !== targetImage.data
      );
      setValue(name, filteredImages);
    }
  };
  useEffect(() => {
    if (currentSlide.current > 0 && images.length === currentSlide.current) {
      if (galleryRef.current) {
        galleryRef.current.slideToIndex(currentSlide.current - 1);
      }
    }
  }, [images.length]);
  if (images.length === 0) {
    return (
      <div>
        <h2>Galeria</h2>
        <div>No hay imagenes disponibles</div>
      </div>
    );
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h2>Galeria</h2>
        <div>
          {images.length > 0 && (
            <Button color="danger" onClick={onDelete}>
              <i className="fa fa-times"></i>
            </Button>
          )}
        </div>
      </div>
      <ImageGallery
        ref={galleryRef}
        items={images.map((image) => {
          return {
            original: image.data,
            originalHeight: 500,
            originalWidth: 500,
            thumbnail: image.data,
          };
        })}
        set
        startIndex={currentSlide.current}
        onSlide={(slideIndex) => (currentSlide.current = slideIndex)}
        infinite={false}
        autoPlay={false}
        showPlayButton={false}
        showFullscreenButton={false}
      />
    </div>
  );
};

export default ImageGalleryForm;
