export const labelMapping = {
  parabrisas: "Parabrisas",
  vidriolat: "Vidrios Laterales",
  vidriotra: "Vidrios Traseros",
  tapiz: "Tapiz",
  tablero: "Tablero",
  puertas: "Puertas",
  techo: "Techo",
  parachoques: "Parachoques",
  mascara: "Mascara",
  capot: "Capot",
  tapamaleta: "Tapamaleta",
  tapabarros: "Tapabarros",
  alzavidrioElectrico: "Alzavidrios Eléctricos",
  espejosLatElectricos: "Espejos Laterales Eléctricos",
  techoPanoramico: "Techo Panorámico",
  sunroof: "Sunroof",
  numerointerno: "N° Interno",
  mandante: "Mandante",
  categoria: "Categoria del bien",
  tipo: "Tipo del bien",
  marca: "Marca",
  modelo: "Modelo",
  ano: "Año",
  color: "Color",
  patente: "Patente",
  motor: "Motor",
  chasis: "Chasis",
  kms: "KMS",
  horas: "Horas",
  codigo: "N° Operación",
  permiso: "Permiso de circulación",
  revision: "Revisión Técnica",
  certificacion: "Certificación de gases",
  seguro: "Seguro obligatorio",
  llaves: "Llaves",
  triangulos: "Triangulos",
  gata: "Gata Rueda",
  llaveRuedas: "Llaves Rueda",
  herramientas: "Herramientas",
  extintor: "Extintor",
  botiquin: "Botiquin",
  encendedor: "Toma corriente 12V",
  antena: "Antena",
  parrilla: "Parrilla",
  viseras: "Viseras",
  alarma: "Alarma",
  cinturones: "Cinturones",
  pisos: "Piso de Goma",
  pisios: "Piso de Goma",
  combustible: "Combustible",
  neblineros: "Neblineros",
  espejo: "Espejo Interior",
  cenicero: "Cenicero",
  radio: "Radio",
  parlantes: "Parlantes",
  tapasEst: "Tapas Est comb",
  ac: "A/C",
  dirH: "Dic Hidraulica",
  tapasRuedas: "Tapas Ruedas",
  plumillas: "Plumillas",
  llantas: "Llantas",
  neumaticos: "Neumaticos",
  neumaticosRepuesto: "Neumaticos Respuesto",
  tercerEje: "Tercer eje",
  quintaRueda: "Quinta Rueda",
  bateria: "Bateria",
  tapasVarillas: "Tapas y varillas",
  espejosExt: "Espejos Ext",
  focosDelanteros: "Focos delanteros",
  focosTraseros: "Fotos traseros",
  pantallaTactil: "Pantalla Tactil",
  mandoAlVolante: "Mando al Volante",
  carplayAndroidAuto: "Carplay / Android Auto",
  velocidadCrucero: "Velocidad Crucero",
  keyless: "KeyLess",
  gps: "GPS",
  asientoElectricos: "Asiento electrico",
  usb: "USB",
  usbC: "USB-C",
  cambios: "Cambios",
  cajaCambios: "Caja de Cambios",
  camaraTrasera: "Cámara trasera",
  camaraDelanteraO360: "Cámara Delantera o 360",
  cilindrada: "Cilindrada",
  transmisionDiferencial: "Transmision/diferencial",
  tipoTransmision: "Tipo de Transmisión",
  rodadosNeumaticos: "Rodados/Neumaticos",
  sistemaElectrico: "Sistema Electrico",
  sistemaHidraulico: "Sistema Hidraulico",
  otros: "Otros",
  funciona: "Funciona",
  tipoCombustible: "Tipo de combustible",
  adblue: "AdBlue",
  traccion: "Tracción",
  cierreCentralizado: "Cierre Centralizado",
  isofix: "Isofix",
  sensores: "Sensores",
  airbag: "Airbag",
  controlEstabilidad: "Control de estabilidad",
  comments: "Observaciones Generales",
  alarmaRetroceso: "Alarma de Retroseso",
  camaraRetroceso: "Cámara de Retroseso",
  brazos: "Brazos",
  carroceria: "Carrocería",
  transmision: "Transmisión",
  sisElectrico: "Sistema Eléctrico",
  sisHidraulico: "Sistema Hidráulico",
};
