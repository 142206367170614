import React, { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import { Input, Label } from "reactstrap";
import { labelMapping } from "../../utils/constants";
function isValidNumberOrEmpty(value) {
  return (typeof value === "number" && value >= 0) || value === "";
}
const InputCheckNumberControlled = ({ name, rules }) => {
  const { control, setValue } = useFormContext();
  const {
    field,
    formState: { defaultValues },
  } = useController({ name, control, rules });
  const value = field?.value?.value ?? {};
  const textLabel = name.split(".").pop();

  useEffect(() => {
    if (!field.value) {
      setValue(name, {
        value: {
          checked: false,
          obs: "",
          quantity: undefined,
        },
      });
    }
  }, [defaultValues]);

  if (!field.value) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-sm-4">
        <Label className=""> {labelMapping[textLabel]}</Label>
      </div>
      <div className="col-sm-1">
        <Input
          checked={value?.checked ?? false}
          onChange={(e) => {
            field.onChange({
              ...field?.value,
              value: { ...value, checked: e.target.checked },
            });
          }}
          type="checkbox"
        />
      </div>
      <div className="col-sm-3">
        <Input
          value={(value?.quantity && Number(value?.quantity)) ?? ""}
          type="number"
          placeholder="Cantidad"
          onChange={(e) => {
            const newValue =
              e.target?.value === "" ? "" : Number(e.target?.value);

            if (isValidNumberOrEmpty(newValue)) {
              field.onChange({
                ...field?.value,
                value: { ...value, quantity: newValue },
              });
            }
          }}
        />
      </div>
      <div className="col-4">
        <Input
          maxLength="55"
          value={value?.obs ?? ""}
          onChange={(e) => {
            field.onChange({
              ...field?.value,
              value: { ...value, obs: e.target?.value.toUpperCase() },
            });
          }}
        />
      </div>
    </div>
  );
};

export default InputCheckNumberControlled;
