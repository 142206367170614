import { useRef, useEffect, useCallback } from "react";

/**
 * @template F
 * @param {F} callback - La función que será llamada después del retraso.
 * @param {number} delay - El tiempo de espera en milisegundos.
 * @returns {{ debounceFn: F, cancel: () => void }} - Devuelve la función debounced y una función para cancelar el temporizador.
 */
const useDebounce = (callback, delay) => {
  /** @type {React.MutableRefObject<ReturnType<typeof setTimeout> | null>} */
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  /** @type {F} */
  const debounceFn = useCallback(
    (...args) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  const cancel = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  return {
    debounceFn,
    cancel,
  };
};

export default useDebounce;
