import { useMutation } from "react-query";
import { axiosApi } from "../../redux/actions";

const useUpdateResource = ({ resource = "", id = "", options = {} }) => {
  const mutation = useMutation({
    ...options,
    mutationFn: async (value) => {
      const url = id ? resource.replace(":id", id) : resource;
      return await axiosApi.put(url, value).then((res) => res.data);
    },
  });
  return mutation;
};

export default useUpdateResource;
