import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import "react-html5-camera-photo/build/css/index.css";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 720,
  height: 720,
  facingMode: "environment",
};

function WebcamContainer() {
  const { setValue } = useFormContext();
  const webcamRef = useRef(null);
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    console.log(imageSrc);
    setValue("photo", { web: true, data: imageSrc });
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <Webcam
          audio={false}
          height={720}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={720}
          mirrored={false}
          videoConstraints={videoConstraints}
        />
      </div>
      <div className="d-flex justify-content-center">
        <button className="btn btn-success w-50" onClick={capture}>
          Tomar Foto
        </button>
      </div>
    </div>
  );
}

export default WebcamContainer;
