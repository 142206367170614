import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Spinner,
  Form,
  Button,
  Input,
  Alert,
  Row,
  Col,
  Table,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { clientList, createClient } from "../../redux/actions/user";
import { useLocation } from "react-router-dom";
import { formFile, getItem } from "../../redux/actions/forms";
import Loading from "../../components/Loading/Loading";

function ItemFile() {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.forms.formFile);
  const itemData = useSelector((state) => state.forms.getItem);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [fileType, setFileType] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState([]);
  const [files, setFiles] = useState();
  const [update, setupdate] = useState(false);
  let history = useHistory();
  const location = useLocation();
  const isAdmin = localStorage.getItem("role");

  useEffect(() => {
    document.title = "BGL - Archivos";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (history.location.state === undefined) {
      return;
    } else if (loading === true) {
      let data = history.location.state;
      console.log(data);
      setData(data);
      adminFiles(data.files);
      setLoading(false);
      setupdate(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (response && loading && update) {
      console.log(response);
      if (response.status === "success") {
        setName("");
        setFile("");
        setFileType("");
        dispatch(getItem(response.data.numerointerno));
      }
      setAlertTitle(response.status);
      setAlertType(response.status);
      setAlertMessage(response.message);
    }
  }, [response]);

  useEffect(() => {
    if (itemData) {
      console.log(itemData);
      if (itemData.status === "success") {
        setData(itemData.data.data);
        let state = itemData.data.data;
        history.replace({ ...history.location, state });
        adminFiles(itemData.data.data.files);
        console.log(itemData);
        setLoading(false);
      }
    }
  }, [itemData]);

  const onDismiss = () => setShowAlert(false);

  const fileCallback = (base) => {
    dispatch(formFile(data.head.numerointerno?.value, name, fileType, base));
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(file);
    if (file.type === "application/pdf") {
      getBase64(file, fileCallback);
    } else {
      setLoading(false);
      setShowAlert(true);
      setAlertTitle("Archivo");
      setAlertType("danger");
      setAlertMessage("Tipo de archivo incorrecto, porfavor subir archivo pdf");
    }
  };

  const getBase64 = (file, callback) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
        callback(baseURL);
      };
    });
  };

  const adminFiles = (auxFiles) => {
    if (isAdmin !== "admin") {
      let filterfiles = auxFiles.filter((row) => row.file_type !== "TAZACION");
      setFiles(filterfiles);
    } else {
      setFiles(auxFiles);
    }
  };

  return loading ? (
    <Loading></Loading>
  ) : (
    <>
      <Container>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>

      <Container style={{ marginTop: 20, marginBottom: 20 }}>
        <h3>Subir archivos</h3>
        <Form onSubmit={(e) => onFormSubmit(e)}>
          <Row>
            <Col>
              <Input
                className="col-md mt-2"
                placeholder="Nombre"
                type="text"
                value={name}
                onChange={(e) => setName(e.target?.value.toUpperCase())}
              />
            </Col>
            <Col>
              <Input
                className="col-md mt-2"
                placeholder="Tipo de archivo"
                type="text"
                value={fileType}
                onChange={(e) => setFileType(e.target?.value.toUpperCase())}
              />
            </Col>
            <Col>
              <Input
                className="col-md mt-2"
                placeholder="Subir Archivo"
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Col>
            <Col>
              <Button className="col-md">Subir</Button>
            </Col>
          </Row>
        </Form>
      </Container>

      <Container>
        <Table hover>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Tipo</th>
              <th>Fecha</th>
              <th>Descargar</th>
            </tr>
          </thead>
          <tbody>
            {files.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row.file_name}</td>
                  <td>{row.file_type}</td>
                  <td>{row.date}</td>
                  <td>
                    <a href={row.url}>
                      Descargar
                      <i className="fa fa-download"></i>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
export default ItemFile;
