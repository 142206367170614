import React, { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import { Col, Input, Label, Row } from "reactstrap";
import InputOptions from "./InputOptions";
import { labelMapping } from "../../utils/constants";

const SelectControlled = ({ name, rules, options = [] }) => {
  const {
    control,
    setValue,
    formState: { defaultValues },
  } = useFormContext();
  const { field } = useController({ name, control, rules });
  const value = field?.value?.value;
  const textLabel = name.split(".").pop();

  useEffect(() => {
    if (!field.value) {
      setValue(name, {});
    }
  }, [defaultValues]);

  if (!field.value) {
    return null;
  }

  return (
    <Row>
      <Label column="true" sm="4">
        {labelMapping[textLabel]}
      </Label>
      <Col>
        <Input
          type="select"
          {...field}
          value={value}
          onChange={(e) =>
            field.onChange({
              ...field?.value,
              value: e.target?.value.toUpperCase(),
            })
          }
        >
          <InputOptions options={options} />
        </Input>
      </Col>
    </Row>
  );
};

export default SelectControlled;
