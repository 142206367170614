import { useQuery } from "react-query";
import { axiosApi } from "../../redux/actions";

const useResource = ({ resource = "", id = "", params = {}, options = {} }) => {
  console.log([resource, id, params]);
  return useQuery({
    ...options,
    queryKey: [resource, id, params],
    queryFn: async () => {
      const url = id ? resource.replace(":id", id) : resource;
      return await axiosApi.get(url, { params }).then((res) => res.data);
    },
  });
};

export default useResource;
