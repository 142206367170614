import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Spinner,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
  Row,
  Col,
  CardText,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { createClient, clientUpdate } from "../../redux/actions/user";
import { useLocation } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

function ClientCreate() {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [rut, setRut] = useState("");
  const [role, setRole] = useState("vendedor");
  const [phone, setPhone] = useState("");
  const [history, setHistory] = useState([]);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [update, setUpdate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const location = useLocation();

  const toggle = () => setOpen(!dropdownOpen);

  useEffect(() => {
    console.log(response);

    if (response.status === "success") {
      setAlertTitle(response.message);
      setAlertMessage(response.message);
      setLoading(false);
      setShowAlert(true);
      setEmail("");
      setName("");
      setPhone("");
    }
  }, [response]);

  useEffect(() => {
    console.log(response, "title");
    document.title = "BGL - Crear Cliente";
    setLoading(false);
  });

  useEffect(() => {
    if (location.state === undefined) {
      return;
    } else if (update === false) {
      let data = location.state.data;
      console.log("Entre en update false: ", data);
      setEmail(data.email);
      setName(data.name);
      setPhone(data.phone);
      setRut(data.rut);
      const historyArray = Object.values(data.history);
      setHistory(historyArray);
      setUpdate(true);
    }
    setLoading(false);
  });

  const onDismiss = () => setShowAlert(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (update) {
      dispatch(clientUpdate(email, rut, name, phone, lastName, history));
    } else {
      dispatch(createClient(email, name, lastName, phone, rut));
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <Alert isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>
      <Container className="container-border p-3 mt-3">
        <Row>
          <Col lg="4"></Col>
          <Col sm="12" lg="12">
            <Form onSubmit={(e) => onFormSubmit(e)}>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="name">Nombre</Label>
                    <Input
                      type="text"
                      placeholder="Nombre..."
                      disabled={loading}
                      onChange={(e) => setName(e.target?.value)}
                      value={name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="text"
                      placeholder="Email..."
                      disabled={loading}
                      onChange={(e) => setEmail(e.target?.value)}
                      value={email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="last-name">Apellido</Label>
                    <Input
                      type="text"
                      placeholder="Apellido"
                      disabled={loading}
                      onChange={(e) => setLastName(e.target?.value)}
                      value={lastName}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="phone">Telefono</Label>
                    <Input
                      type="text"
                      placeholder="Telefono..."
                      disabled={loading}
                      onChange={(e) => setPhone(e.target?.value)}
                      value={phone}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="rut">RUT</Label>
                    <Input
                      type="text"
                      placeholder="Rut..."
                      disabled={loading}
                      onChange={(e) => setRut(e.target?.value)}
                      value={rut}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Label for="historial">Historial de Bienes</Label>
                  <div
                    style={{
                      height: "450px",
                      overflowY: "auto",
                      overflowX: "hidden",
                      marginTop: "10px",
                      padding: "10px",
                    }}
                  >
                    <Row>
                      {history.map((item, index) => (
                        <Col
                          className="mb-2"
                          xs="12"
                          sm="6"
                          md="4"
                          lg="3"
                          key={index}
                        >
                          <Card
                            className="container-border"
                            style={{ height: "100%", width: "100%" }}
                          >
                            <CardBody>
                              <CardTitle tag="h5">
                                {item.numerointerno}
                              </CardTitle>
                              <CardText>
                                <span className="badge badge-success">
                                  ESTADO: {item.state.toUpperCase()}
                                </span>
                              </CardText>
                              <CardText>
                                ORIGEN: {item.origin.toUpperCase()}
                              </CardText>
                              <CardText>FECHA: {item.date}</CardText>
                              <CardText>PRECIO: {item.price}</CardText>
                              <CardText>IVA: {item.iva}</CardText>
                              <CardText>COMISIÓN: {item.comission}</CardText>
                              <CardText>
                                {item.wayToPay == null
                                  ? "FORMA DE PAGO: NO APLICA"
                                  : "FORMA DE PAGO: " + item.wayToPay}
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>
              </Row>
              <Button
                color="success"
                disabled={loading}
                style={{ marginTop: "20px" }}
              >
                Guardar
              </Button>
            </Form>
          </Col>
          <Col lg="4"></Col>
        </Row>
      </Container>
    </>
  );
}
export default ClientCreate;
