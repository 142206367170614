import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Spinner,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
  Row,
  Col,
} from "reactstrap";
import { createOffer, updateOffer } from "../../redux/actions/offer";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

function CreateOffer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLang = useSelector((state) => state.lang.lang);
  const response = useSelector((state) => state.offer.create);
  const responseUpdate = useSelector((state) => state.offer.update);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [wayToPay, setWayToPay] = useState("");
  const [name, setName] = useState("");
  const [rut, setRut] = useState("");
  const [phone, setPhone] = useState("");
  const [obs, setObs] = useState("");
  const [price, setPrice] = useState("");
  const [iva, setIva] = useState("");
  const [comission, setComission] = useState("");
  const [version, setVersion] = useState(1);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const location = useLocation();

  const [update, setupdate] = useState(false);

  useEffect(() => {
    if (response && loading && update) {
      console.log(response);
      if (response.message === "Offer created") {
        setAlertTitle(response.message);
        setAlertMessage(response.message);
        window.open(response.data);

        setLoading(false);
        setShowAlert(true);
        goToOfferList();
      }
    }
    if (responseUpdate && loading && update) {
      console.log(responseUpdate);
      if (responseUpdate.message === "Offer updated") {
        setAlertTitle(responseUpdate.message);
        window.open(responseUpdate.data);

        setLoading(false);
        setShowAlert(true);
        goToOfferList();
      }
    }
  }, [response, responseUpdate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "BGL - Crear Oferta";
  }, []);

  const goToOfferList = () => {
    history.push({
      pathname: "/offerlist",
    });
  };

  useEffect(() => {
    if (location.state === undefined) {
      return;
    } else if (update === false) {
      let data = location.state.data;
      console.log(data);
      setData(data.numerointerno);
      if (data.price) {
        setId(data.id);
        setName(data.name);
        setVersion(data.version);
        setEmail(data.email);
        setPhone(data.phone);
        setObs(data.obs);
        setPrice(data.price);
        setIva(data.iva);
        setComission(data.comission);
        setWayToPay(data.wayToPay);
        setRut(data.rut);
      }
      setLoading(false);
      setupdate(true);
    }
  });

  const onDismiss = () => setShowAlert(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (id) {
      dispatch(
        updateOffer(
          id,
          data,
          name,
          rut,
          email,
          phone,
          obs,
          price,
          iva,
          comission,
          wayToPay,
          version
        )
      );
    } else {
      dispatch(
        createOffer(
          data,
          name,
          rut,
          email,
          phone,
          obs,
          price,
          iva,
          comission,
          wayToPay,
          version
        )
      );
    }
  };

  function numberWithDot(x) {
    let n = x.split(".").join("");
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const onChangeValue = (value) => {
    let n = numberWithDot(value);
    setPrice(n);
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <Alert isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>
      <Container
        className={"p-3 container-border"}
        style={{ marginTop: "10px" }}
      >
        <h2 style={{ textAlign: "center" }}>NUMERO INTERNO: {data}</h2>
        <Form onSubmit={(e) => onFormSubmit(e)}>
          <Row>
            <Col lg="4"></Col>
            <Col sm="12" lg="12">
              <FormGroup>
                <Label for="sirs">Nombre</Label>
                <Input
                  type="tex"
                  placeholder="Nombre..."
                  disabled={loading}
                  onChange={(e) => setName(e.target?.value)}
                  value={name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="rut">RUT</Label>
                <Input
                  type="text"
                  placeholder="RUT..."
                  disabled={loading}
                  onChange={(e) => setRut(e.target?.value)}
                  value={rut}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="text"
                  placeholder="Email..."
                  disabled={loading}
                  onChange={(e) => setEmail(e.target?.value)}
                  value={email}
                />
              </FormGroup>
              <FormGroup>
                <Label for="phone">Telefono</Label>
                <Input
                  type="text"
                  placeholder="Telefono..."
                  disabled={loading}
                  onChange={(e) => setPhone(e.target?.value)}
                  value={phone}
                />
              </FormGroup>
              <FormGroup>
                <Label for="price">Valor</Label>
                <Input
                  type="text"
                  placeholder="Valor..."
                  disabled={loading}
                  onChange={(e) => {
                    const inputValue = e.target?.value;
                    const numericValue = inputValue.replace(/[^0-9.]/g, "");
                    onChangeValue(numericValue);
                  }}
                  value={price}
                />
              </FormGroup>
              <FormGroup>
                <Label for="iva">IVA</Label>
                <Input
                  type="select"
                  disabled={loading}
                  onChange={(e) => setIva(e.target?.value)}
                  value={iva}
                >
                  <option value="" style={{ display: "none" }}>
                    Eliga una opcion
                  </option>
                  <option value="+ IVA">+ IVA</option>
                  <option value="Con IVA">Con IVA</option>
                  <option value="Exento de IVA">Exento de IVA</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="comission">Comisión</Label>
                <Input
                  type="text"
                  placeholder="Comisión..."
                  disabled={loading}
                  onChange={(e) => {
                    const inputValue = e.target?.value;
                    const numericValue = inputValue.replace(/[^0-9.]/g, "");
                    setComission(numericValue);
                  }}
                  value={comission}
                />
              </FormGroup>
              <FormGroup>
                <Label for="contact">Forma de Pago</Label>
                <Input
                  type="select"
                  disabled={loading}
                  onChange={(e) => setWayToPay(e.target?.value)}
                  value={wayToPay}
                >
                  <option value="" style={{ display: "none" }}>
                    Eliga una opción
                  </option>
                  <option value="Crédito">Crédito</option>
                  <option value="Contado">Contado</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg="4"></Col>
          </Row>
          <FormGroup>
            <Label for="name">Observaciones</Label>
            <Input
              type="textarea"
              placeholder="Observaciones..."
              disabled={loading}
              onChange={(e) => setObs(e.target?.value)}
              value={obs}
            />
          </FormGroup>
          <Button disabled={loading}>Guardar</Button>
        </Form>
      </Container>
    </>
  );
}
export default CreateOffer;
