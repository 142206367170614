import React, { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import { Label, Input, Col } from "reactstrap";
import { labelMapping } from "../../utils/constants";

const InputControlled = ({ name, rules }) => {
  const {
    control,
    setValue,
    formState: { defaultValues },
  } = useFormContext();
  const { field } = useController({ name, control, rules });
  const value = field?.value?.value;
  const textLabel = name.split(".").pop();

  useEffect(() => {
    if (!field.value) {
      setValue(name, { value: "" });
    }
  }, [defaultValues]);

  if (!field.value) {
    return null;
  }

  return (
    <div className="row">
      <Label column="true" sm="3">
        {labelMapping[textLabel]}
      </Label>
      <Col sm="9">
        <Input
          {...field}
          type="text"
          value={value ?? ""}
          onChange={(e) =>
            field.onChange({
              ...field?.value,
              value: e.target?.value.toUpperCase(),
            })
          }
        />
      </Col>
    </div>
  );
};
export default InputControlled;
