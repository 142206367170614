import React, { useEffect, useState } from "react";
import { Container, Button } from "reactstrap";
import WebcamContainer from "./WebcamContainer";
import { useHistory } from "react-router-dom";
import "react-html5-camera-photo/build/css/index.css";
import Loading from "../Loading/Loading";
import useResource from "../../api/services/useResource";
import { FormProvider, useForm } from "react-hook-form";
import useCreateResource from "../../api/services/useCreateResource";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { confirmAlert } from "../ui/ConfirmAlert";
import usePageTitle from "../../hooks/usePageTitle";
function TakePhoto(props) {
  usePageTitle("BGL - Ingreso de Bienes");
  const { numerointerno } = props.match.params;
  const history = useHistory();
  const { mutate: uploadImage } = useCreateResource({
    resource: "/item/image",
  });
  const { data: itemData, isLoading } = useResource({
    resource: "/item/form",
    params: { numerointerno},
  });
  const formMethods = useForm({ defaultValues: { photo: "" } });

  const [imagesGallery, setImageGallery] = useState([]);

  const currentPhoto = formMethods.watch("photo");

  const clearCurrentPhoto = () => {
    formMethods.setValue("photo", undefined);
  };

  //TODO: guardar Imagenes con acta True
  const saveImage = () => {
    uploadImage(
      { data: { numerointerno, acta: true, images: [currentPhoto.data] } },
      {
        onSuccess: (resData) => {
          console.log(resData);
          setImageGallery((state) => [...state, resData.urls]);
          clearCurrentPhoto();
        },
      }
    );
  };

  const handleFinish = () => {
    confirmAlert({
      title: "Terminar toma de fotos",
      question:
        "¿Estas seguro que quieres terminar la toma de fotos y guardar el Bien?",
      confirmText: "Guardar",
      onConfirm: () => history.push("/create-items"),
    });
  };

  useEffect(() => {
    if (itemData?.data?.data?.img) {
      setImageGallery(itemData?.data?.data?.img?.map((img) => img.data));
    }
  }, [itemData]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <FormProvider {...formMethods}>
      <Container>
        {!currentPhoto?.data ? (
          <div>
            <WebcamContainer />
          </div>
        ) : (
          <div className="">
            <div className="d-flex justify-content-center">
              <img src={currentPhoto.data} alt="sin imagen" />
            </div>
            <div className="d-flex justify-content-between mt-2">
              <Button className="btn btn-success" onClick={saveImage}>
                Guardar Imagen
              </Button>
              <Button className="btn btn-danger" onClick={clearCurrentPhoto}>
                Volver a tomar
              </Button>
            </div>
          </div>
        )}

        <div>
          <h2>Galeria</h2>
          <ImageGallery
            items={imagesGallery.map((src) => {
              return {
                original: src,
                originalHeight: 500,
                originalWidth: 500,
                thumbnail: src,
              };
            })}
            infinite={false}
            autoPlay={false}
            showPlayButton={false}
            showFullscreenButton={false}
          />
        </div>
        <Button className={"btn btn-danger w-100 mt-2"} onClick={handleFinish}>
          Terminar
        </Button>
      </Container>
    </FormProvider>
  );
}

export default TakePhoto;
