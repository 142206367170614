import React from "react";
import FormHeader from "../Item/FormHeader";
import InputCheckNumberControlled from "../ui/InputCheckNumberControlled";
import InputCheckControlled from "../ui/InputCheckControlled";
import InputAreaControlled from "../ui/InputAreaControlled";

const TruckFormFields = () => {
  return (
    <div>
      <div className="container-border p-4">
        <FormHeader />
      </div>
      <div className="mt-2 container-border p-4">
        <h3>Inspección de Carroceria</h3>
        <InputCheckNumberControlled name={`bodyinspection.parabrisas`} />
        <InputCheckNumberControlled name={`bodyinspection.vidriolat`} />
        <InputCheckNumberControlled name={`bodyinspection.vidriotra`} />
        <InputCheckNumberControlled name={`bodyinspection.tapiz`} />
        <InputCheckNumberControlled name={`bodyinspection.tablero`} />
        <InputCheckNumberControlled name={`bodyinspection.puertas`} />
        <InputCheckNumberControlled name={`bodyinspection.techo`} />
        <InputCheckNumberControlled name={`bodyinspection.parachoques`} />
        <InputCheckNumberControlled name={`bodyinspection.mascara`} />
        <InputCheckNumberControlled name={`bodyinspection.capot`} />
        <InputCheckNumberControlled name={`bodyinspection.tapamaleta`} />
        <InputCheckNumberControlled name={`bodyinspection.tapabarros`} />
        <InputCheckNumberControlled
          name={`bodyinspection.alzavidrioElectrico`}
        />

        <InputCheckNumberControlled name={`bodyinspection.otros`} />
      </div>
      <div className="mt-2 container-border p-4">
        <h3>Accesorios e Inventario</h3>
        <InputCheckNumberControlled name={`accesoriesInventory.llaves`} />
        <InputCheckNumberControlled name={`accesoriesInventory.triangulos`} />
        <InputCheckNumberControlled name={`accesoriesInventory.gata`} />
        <InputCheckNumberControlled name={`accesoriesInventory.llaveRuedas`} />
        <InputCheckNumberControlled name={`accesoriesInventory.herramientas`} />
        <InputCheckNumberControlled name={`accesoriesInventory.extintor`} />
        <InputCheckNumberControlled name={`accesoriesInventory.botiquin`} />
        <InputCheckNumberControlled name={`accesoriesInventory.encendedor`} />
        <InputCheckNumberControlled name={`accesoriesInventory.antena`} />
        <InputCheckNumberControlled name={`accesoriesInventory.parrilla`} />
        <InputCheckNumberControlled name={`accesoriesInventory.viseras`} />
        <InputCheckNumberControlled name={`accesoriesInventory.cinturones`} />
        <InputCheckNumberControlled name={`accesoriesInventory.pisos`} />
        <InputCheckNumberControlled name={`accesoriesInventory.neblineros`} />
        <InputCheckNumberControlled name={`accesoriesInventory.espejo`} />
        <InputCheckNumberControlled name={`accesoriesInventory.cenicero`} />
        <InputCheckNumberControlled name={`accesoriesInventory.radio`} />
        <InputCheckNumberControlled name={`accesoriesInventory.parlantes`} />
        <InputCheckNumberControlled name={`accesoriesInventory.tapasEst`} />
        <InputCheckNumberControlled name={`accesoriesInventory.ac`} />
        <InputCheckNumberControlled name={`accesoriesInventory.dirH`} />
        <InputCheckNumberControlled name={`accesoriesInventory.tapasRuedas`} />
        <InputCheckNumberControlled name={`accesoriesInventory.plumillas`} />
        <InputCheckNumberControlled name={`accesoriesInventory.llantas`} />
        <InputCheckNumberControlled name={`accesoriesInventory.neumaticos`} />
        <InputCheckNumberControlled
          name={`accesoriesInventory.neumaticosRepuesto`}
        />
        <InputCheckNumberControlled name={`accesoriesInventory.tercerEje`} />
        <InputCheckNumberControlled name={`accesoriesInventory.quintaRueda`} />
        <InputCheckNumberControlled name={`accesoriesInventory.bateria`} />
        <InputCheckNumberControlled
          name={`accesoriesInventory.tapasVarillas`}
        />
        <InputCheckNumberControlled name={`accesoriesInventory.espejosExt`} />
        <InputCheckNumberControlled
          name={`accesoriesInventory.focosDelanteros`}
        />
        <InputCheckNumberControlled
          name={`accesoriesInventory.focosTraseros`}
        />

        <InputCheckNumberControlled
          name={`accesoriesInventory.mandoAlVolante`}
        />

        <InputCheckNumberControlled
          name={`accesoriesInventory.velocidadCrucero`}
        />
        <InputCheckNumberControlled
          name={`accesoriesInventory.asientoElectricos`}
        />
        <InputCheckNumberControlled name={`accesoriesInventory.usb`} />
        <InputCheckNumberControlled name={`accesoriesInventory.usbC`} />
        <InputCheckNumberControlled name={`accesoriesInventory.otros`} />
      </div>
      <div className="container-border p-4 mt-2">
        <h3>Seguridad</h3>
        <InputCheckControlled name={`safetyness.cierreCentralizado`} />
        <InputCheckControlled name={`safetyness.sensores`} />
        <InputCheckControlled name={`safetyness.airbag`} />
        <InputCheckControlled name={`safetyness.alarma`} />
        <InputCheckControlled name={`safetyness.alarmaRetroceso`} />
        <InputCheckControlled name={`safetyness.camaraRetroceso`} />
        <InputCheckControlled name={`safetyness.controlEstabilidad`} />
      </div>
      <div className="container-border p-4 mt-2">
        <h3>Observaciones Generales</h3>
        <InputAreaControlled name="comments" />
      </div>
    </div>
  );
};

export default TruckFormFields;
