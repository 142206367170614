import React, { useEffect } from "react";
import InputControlled from "../ui/InputControlled";
import { emptyOption } from "../ui/InputOptions";
import SelectControlled from "../ui/SelectControlled";
import InputCheckControlled from "../ui/InputCheckControlled";
import useResource from "../../api/services/useResource";
import { useFormContext } from "react-hook-form";

const FormHeader = () => {
  const { watch } = useFormContext();
  const { data: providers } = useResource({ resource: "/api/providers" });
  const { data: categories } = useResource({ resource: "/api/categories" });

  const providersOptions =
    providers?.providers?.map((provider) => {
      return {
        label: provider.name,
        value: provider.name,
      };
    }) ?? [];
  const categoriesOptions =
    categories?.data?.map((category) => ({
      value: category.category_name,
      label: category.category_name,
    })) ?? [];

  const categoryValue = watch("head.categoria")?.value;
  const categoriesTypeOptions =
    (categoryValue &&
      categories?.data
        ?.find((item) => item.category_name === categoryValue)
        ?.types?.map((type) => ({
          value: type.name,
          label: type.name,
        }))) ??
    [];

  return (
    <div>
      <div>
        <div className="">
          <SelectControlled
            name={`head.mandante`}
            options={[emptyOption, ...providersOptions]}
            rules={{ required: true }}
          />
        </div>
        <div className="mt-2">
          <SelectControlled
            name={`head.categoria`}
            options={[emptyOption, ...categoriesOptions]}
            rules={{ required: true }}
          />
        </div>
        <div className="mt-2">
          <SelectControlled
            name={`head.tipo`}
            options={[emptyOption, ...categoriesTypeOptions]}
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className={"col-md-5"}>
          <h3>Identificacion del Bien</h3>
          <InputControlled name={`head.marca`} />
          <InputControlled name={`head.modelo`} />
          <InputControlled name={`head.ano`} />
          <InputControlled name={`head.color`} />
          <InputControlled name={`head.patente`} />
          <InputControlled name={`head.motor`} />
          <InputControlled name={`head.chasis`} />
          <InputControlled name={`head.kms`} />
          <InputControlled name={`head.horas`} />
          <InputControlled name={`head.codigo`} />
        </div>
        <div className={"col-md-7"}>
          <h3>Documentos</h3>
          <InputCheckControlled name={`head.permiso`} />
          <InputCheckControlled name={`head.revision`} />
          <InputCheckControlled name={`head.certificacion`} />
          <InputCheckControlled name={`head.seguro`} />
        </div>
      </div>
    </div>
  );
};
export default FormHeader;
