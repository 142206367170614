import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import { Container, Collapse, Table } from "reactstrap";
import "react-toggle/style.css";
import useDebounce from "../../hooks/useDebounce";
import { isEqual } from "lodash";
import { createForms } from "../../redux/actions/forms";
import { useDispatch } from "react-redux";
import Loading from "../Loading/Loading";
import { labelMapping } from "../../utils/constants";
const SECTION_NAMES = {
  head: "Especificaciones",
  bodyinspection: "Inspección",
  accesoriesInventory: "Accesorios e Inventario",
  mecanicState: "Estado Mecanico",
  safetyness: "Seguridad",
};
const hrStyle = {
  backgroundColor: "#000000",
  height: 0.5,
  color: "#000000",
  borderColor: "#000000",
};

const ChevromDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-chevron-down"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
      />
    </svg>
  );
};

function ItemInfo({ data }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(data);
  const [collapsedItem, setCollapsedItem] = useState({});

  const toggleSection = (key) => {
    setCollapsedItem((prev) => ({ ...prev, [key]: !!!prev[key] }));
  };

  const handleToggle = (e, sectionKey, name) => {
    setFormData((prev) => {
      return {
        ...prev,
        [sectionKey]: {
          ...prev[sectionKey],
          [name]: {
            ...prev[sectionKey][name],
            isPublic: e.target.checked,
          },
        },
      };
    });
  };

  const { debounceFn } = useDebounce((form) => {
    dispatch(createForms(form));
  }, 2000);

  useEffect(() => {
    setFormData(formData);
  }, [formData]);
  useEffect(() => {
    if (formData && !isEqual(formData, data)) {
      debounceFn(formData);
    }
  }, [formData]);

  console.log({ formData });
  if (!formData) return <Loading />;
  return (
    <div>
      {Object.keys(SECTION_NAMES).map((sectionKey) => {
        if (!(sectionKey in SECTION_NAMES) || !formData[sectionKey])
          return null;

        const section = formData[sectionKey];
        const itemsKeys = Object.keys(section);
        return (
          <Container key={sectionKey}>
            <div
              onClick={() => toggleSection(sectionKey)}
              className="d-flex align-items-center"
            >
              <h3 className="flex-grow-1 m-0">{SECTION_NAMES[sectionKey]}</h3>
              <ChevromDown />
            </div>
            <hr style={hrStyle} />
            <Collapse isOpen={!!collapsedItem[sectionKey]}>
              <Table>
                <tbody>
                  {itemsKeys.map((itemKey, index) => {
                    const rowItem = formData[sectionKey][itemKey];
                    const itemLabel = itemKey;
                    const textLabel = labelMapping[itemLabel] ?? itemLabel;
                    console.log(itemKey, labelMapping[itemLabel]);
                    const isChecked = rowItem.isPublic ?? false;
                    if (typeof rowItem.value === "string")
                      return (
                        <tr key={itemKey}>
                          <td>{textLabel}</td>
                          <td> {rowItem.value}</td>
                          <td> </td>
                          <td> </td>
                          <td>
                            <Toggle
                              checked={isChecked}
                              onChange={(e) =>
                                handleToggle(e, sectionKey, itemKey)
                              }
                            />
                          </td>
                        </tr>
                      );
                    return (
                      <tr key={itemKey}>
                        <td className="text-capitalize">{textLabel}</td>
                        <td>
                          {rowItem.value?.checked === "on" ||
                          rowItem.value?.checked === true
                            ? "SI"
                            : "No"}
                        </td>
                        <td>{rowItem.value?.quantity}</td>
                        <td> {rowItem.value?.obs}</td>
                        <td>
                          <Toggle
                            checked={isChecked}
                            onChange={(e) =>
                              handleToggle(e, sectionKey, itemKey)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Collapse>
          </Container>
        );
      })}
    </div>
  );
}
export default ItemInfo;
