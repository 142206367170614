import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Button, Input, Alert, Table } from "reactstrap";
import { getForms } from "../../redux/actions/forms";
import Loading from "../../components/Loading/Loading";

function ListItems() {
  const dispatch = useDispatch();
  let history = useHistory();
  const response = useSelector((state) => state.forms.forms);
  const [loading, setLoading] = useState(true);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [filterNI, setfilterNI] = useState("");
  const [filterMandante, setfilterMandante] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "BGL - Sign In";
    window.scrollTo(0, 0);

    dispatch(getForms({ view_type: "list" }));
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status === "success") {
        const updatedData = response.data.map(item => {
          if (!item.data.safetyness) {
            return {
              ...item,
              data: {
                ...item.data,
                safetyness: {}
              }
            };
          }
          return item;
        });

        setLoading(false);
        setData(updatedData);
      }
      setAlertTitle(response.status);
      setAlertType(response.status);
      setAlertMessage(response.message);
    }
  }, [response]);

  const onDismiss = () => setShowAlert(false);
  const goToItem = (numerointerno) => {
    history.push(`/edit-item/${numerointerno}`);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Container className="container-border">
      <Container>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>
      <Container style={{ marginTop: 20, marginBottom: 20 }}>
        <h3>Filtros</h3>
        <Input
          className="col-md-8 mb-1"
          placeholder="Numero Interno"
          type="text"
          value={filterNI}
          onChange={(e) => {
            setfilterNI(e.target?.value);
          }}
        />
        <Input
          className="col-md-8"
          placeholder="Mandante"
          type="text"
          value={filterMandante}
          onChange={(e) => setfilterMandante(e.target?.value)}
        />
      </Container>
      <Container>
        <Table hover>
          <thead>
            <tr>
              <th>Número Interno</th>
              <th>Nombre</th>
              <th>Mandante</th>
              <th>Categoria</th>
              <th>Aprobado por</th>
              <th>Ver</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              return row.data.head.numerointerno?.value.includes(filterNI) &
                row.data.head.mandante?.value.includes(filterMandante) ? (
                <tr key={index}>
                  <td>{row.data.head.numerointerno?.value}</td>
                  <td>{` ${row.data.head?.ano?.value} ${row.data.head.marca?.value} ${row.data.head.modelo?.value}`}</td>
                  <td>{row.data.head.mandante?.value}</td>
                  <td>{row.data.head.categoria?.value}</td>
                  <td>
                    {row.data.approved_by?.map((item, i) => {
                      return <p key={i}>{item}</p>;
                    })}
                  </td>
                  <td>
                    {
                      <Button
                        onClick={() => {
                          goToItem(row.data.head.numerointerno.value);
                        }}
                      >
                        <i className="fa fa-eye"></i>
                      </Button>
                    }
                  </td>
                </tr>
              ) : (
                <tr key={index}></tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </Container>
  );
}
export default ListItems;
