import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { Input } from "reactstrap";

const InputAreaControlled = ({ name, rules }) => {
  const { control } = useFormContext();
  const { field } = useController({ name, control, rules });

  return <Input {...field} type="textarea" />;
};

export default InputAreaControlled;
