import React from "react";

/**
 * InputOptions Component
 * @param {Array<{ label: string, value: string }>} options - Array de objetos que representan las opciones del `<select>`.
 * @returns {JSX.Element[]} - Una lista de etiquetas `<option>` generadas.
 */

const InputOptions = ({ options }) => {
  return (
    <React.Fragment>
      {options.map((option) => (
        <option key={option?.value} value={option?.value}>
          {option.label}
        </option>
      ))}
    </React.Fragment>
  );
};

export const emptyOption = { label: "", value: "" };
export default InputOptions;
