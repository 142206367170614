import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Spinner,
  Button,
  Label,
  Input,
  Alert,
  Row,
  Col,
} from "reactstrap";

import ImageUploader from "react-images-upload";
import Loading from "../../components/Loading/Loading";
import useResource from "../../api/services/useResource";
import useUpdateResource from "../../api/services/useUpdateResource";
import { queryClient } from "../../api/queryClient";
import useCreateResource from "../../api/services/useCreateResource";

function convertImagesToBase64(imagesArray) {
  return Promise.all(
    imagesArray.map((imageFile) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);

        // Leer el archivo como una cadena en formato Base64
        reader.readAsDataURL(imageFile);
      });
    })
  );
}

function ImageItem(props) {
  useEffect(() => {
    document.title = "BGL - Bienes";
    window.scrollTo(0, 0);
  }, []);

  const { numerointerno } = props.match.params;
  const { data: itemData, isLoading } = useResource({
    resource: "/item/form",
    params: { numerointerno },
  });

  const { mutate: updateFirstItem } = useUpdateResource({
    resource: "/item/form/first",
  });

  const { mutate: uploadImage, isLoading: isUploadingImage } =
    useCreateResource({
      resource: "/item/image",
    });

  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState([]);

  const images = itemData?.data?.data?.img ?? [];
  const uploadImagesEnabled = imagesToUpload.length > 0;

  const onDismiss = () => setShowAlert(false);

  const setMainImage = (selectedImage) => {
    if (selectedImage.first) return;

    const copyClean = images.map((image) => {
      const { first, ...rest } = image;
      return rest;
    });
    const filtered = copyClean.filter(
      (image) => image.data !== selectedImage.data
    );
    const imagesUpdated = [{ ...selectedImage, first: true }, ...filtered];

    updateFirstItem(
      { data: { numerointerno, img: imagesUpdated } },
      {
        onSuccess: (res) => {
          queryClient.setQueriesData(
            ["/item/form", "", { numerointerno }],
            (prev) => {
              return { ...prev, data: { ...prev.data, data: res.data } };
            }
          );
        },
      }
    );
  };

  //TODO: manejar iamgenes con Acta False
  const handleUploadImage = async () => {
    const images64 = await convertImagesToBase64(imagesToUpload);
    uploadImage(
      { data: { numerointerno, acta: false, images: images64 } },
      {
        onSuccess: (resData) => {
          console.log({ resData });
          queryClient.invalidateQueries({
            queryKey: ["/item/form", "", { numerointerno }],
          });
          setImagesToUpload([]);
        },
      }
    );
  };

  const changeImageState = (item) => {
    const img = images.map((image) => {
      if (image.data === item.data) return { ...item, web: !item.web };
      return image;
    });
    updateFirstItem(
      { data: { numerointerno, img } },
      {
        onSuccess: (res) => {
          queryClient.setQueriesData(
            ["/item/form", "", { numerointerno }],
            (prev) => {
              return { ...prev, data: { ...prev.data, data: res.data } };
            }
          );
        },
      }
    );
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Container>
      <div>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </div>
      <div>
        <h2>Imagenes</h2>
        <Row>
          <ImageUploader
            withIcon
            withPreview
            onChange={setImagesToUpload}
            buttonText="Seleccionar Imagenes"
            imgExtension={[".jpg", ".jpeg",".png"]}
            maxFileSize={524288000}
          />

          <Button
            onClick={handleUploadImage}
            disabled={!uploadImagesEnabled || isUploadingImage}
          >
            guardar Imagenes
          </Button>
        </Row>
        <Row>
          <Col lg="12">
            <Container fluid={true}>
              <Row>
                {images.map((image, indexCol) => {
                  return (
                    <Col xs="6" sm="4" md="3" key={indexCol} className="m-6">
                      <a
                        href={image.data}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={image.data} alt="Imagen no disponible" />
                      </a>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          key={indexCol}
                          className="custom-control-input"
                          id={image.data}
                          checked={image.web}
                          onChange={() => changeImageState(image)}
                        />

                        <div
                          className="mt-2 d-flex justify-content-end"
                          style={{ cursor: "pointer" }}
                        >
                          <div onClick={() => setMainImage(image)}>
                            <i
                              className={
                                image.first ? "fa fa-star" : "far fa-star"
                              }
                              style={{ color: "black" }}
                            />
                          </div>
                        </div>
                        <label
                          className="custom-control-label"
                          htmlFor={image.data}
                        >
                          Mostrar en Pagina Web
                        </label>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default ImageItem;
