export const DEFAULT_CAR_FORM = {
  type: "car",
  head: {
    marca: { isPublic: true },
    modelo: { isPublic: true },
    ano: { isPublic: true },
    kms: { isPublic: true },
    horas: { isPublic: true },
  },
  bodyinspection: {},
  accesoriesInventory: {
    ac: { isPublic: true },
    carplayAndroidAuto: { isPublic: true },
    keyless: { isPublic: true },
  },
  mecanicState: {
    cilindrada: { isPublic: true },
    tipoTransmision: { isPublic: true },
    tipoCombustible: { isPublic: true },
    traccion: { isPublic: true },
  },
  safetyness: {},
  comments: "",
};

export const DEFAULT_TRUCK_FORM = {
  type: "truck",
  head: {
    marca: { isPublic: true },
    modelo: { isPublic: true },
    ano: { isPublic: true },
    kms: { isPublic: true },
    horas: { isPublic: true },
  },
  bodyinspection: {},
  accesoriesInventory: {
    ac: { isPublic: true },
  },
  mecanicState: {
    cilindrada: { isPublic: true },
    tipoTransmision: { isPublic: true },
    tipoCombustible: { isPublic: true },
    traccion: { isPublic: true },
  },
  safetyness: {},
  comments: "",
};

export const DEFAULT_MACHINERY_FORM = {
  type: "machinery",
  head: {
    marca: { isPublic: true },
    modelo: { isPublic: true },
    ano: { isPublic: true },
    kms: { isPublic: true },
    horas: { isPublic: true },
  },
  bodyinspection: {},
  accesoriesInventory: {
    ac: { isPublic: true },
  },
  mecanicState: {
    cilindrada: { isPublic: true },
    tipoTransmision: { isPublic: true },
    tipoCombustible: { isPublic: true },
    traccion: { isPublic: true },
  },
  safetyness: {},
  comments: "",
};

export const DEFAULT_MANY = {
  type: "many",
  head: {
    marca: { isPublic: true },
    modelo: { isPublic: true },
    ano: { isPublic: true },
    kms: { isPublic: true },
    horas: { isPublic: true },
    bodyinspection: {},
  },

  comments: "",
};
