import { types, API_URL } from "./types";
import Axios from "axios";

export const createProvider = (name, number) => {
  const url = `${API_URL}/api/providers`;

  return (dispatch) => {
    let outputData = {};
    let params = {
      name: name,
      identifier: number,
    };
    Axios.post(url, params).then(({ data }) => {
      if (data.error) {
        outputData["status"] = "danger";
        outputData["message"] = "Error creating form";
        dispatch(setCreateProvider(outputData));
      } else {
        outputData["status"] = data.status;
        outputData["message"] = data.message;
        dispatch(setCreateProvider(outputData));
      }
    });
  };
};

export const setCreateProvider = (data) => ({
  type: types.createProvider,
  payload: data,
});

export const createCategory = (value, name) => {
  const url = `${API_URL}/api/categories`;

  return (dispatch) => {
    let outputData = {};
    let params = {
      action: "category",
      name: name,
      value: value,
    };
    Axios.post(url, params).then(({ data }) => {
      console.log(data);

      if (data.error) {
        outputData["status"] = "danger";
        outputData["message"] = "Error creating form";
        dispatch(setCreateCategory(outputData));
      } else {
        outputData["status"] = data.status;
        outputData["message"] = data.message;
        dispatch(setCreateCategory(outputData));
      }
    });
  };
};

export const setCreateCategory = (data) => ({
  type: types.createCategory,
  payload: data,
});

export const createCategoryType = (id, name) => {
  const url = `${API_URL}/api/categories`;

  return (dispatch) => {
    let outputData = {};
    let params = {
      action: "types",
      category: id,
      name: name,
    };
    Axios.post(url, params).then(({ data }) => {
      if (data.error) {
        outputData["status"] = "danger";
        outputData["message"] = "Error creating form";
        dispatch(setCreateCategoryType(outputData));
      } else {
        outputData["status"] = data.status;
        outputData["message"] = data.message;
        dispatch(setCreateCategoryType(outputData));
      }
    });
  };
};

export const setCreateCategoryType = (data) => ({
  type: types.createCategoryType,
  payload: data,
});

export const createReport = (start, end, provider, category, states) => {
  const url = `${API_URL}/reports`;

  return (dispatch) => {
    let outputData = {};
    let params = {
      data: {
        start: start,
        end: end,
        provider: provider?.value,
        category: category?.value,
        states: states,
      },
    };
    Axios.post(url, params).then(({ data }) => {
      if (data.error) {
        outputData["status"] = "danger";
        outputData["message"] = "Error creating form";
        dispatch(setCreateReport(outputData));
      } else {
        outputData["data"] = data.data;
        outputData["status"] = data.status;
        outputData["message"] = data.message;
        dispatch(setCreateReport(outputData));
      }
    });
  };
};

export const setCreateReport = (data) => ({
  type: types.createReport,
  payload: data,
});

export const getProvider = () => {
  let url = `${API_URL}/api/providers`;

  return (dispatch) => {
    let outputData = {};

    Axios.get(url).then(({ data }) => {
      if (data.error) {
        outputData["status"] = "danger";
        outputData["message"] = "Error creating form";
        dispatch(setGetProvider(outputData));
      } else {
        outputData["data"] = data.providers;
        outputData["message"] = data.message;
        dispatch(setGetProvider(outputData));
      }
    });
  };
};

export const setGetProvider = (data) => ({
  type: types.getProvider,
  payload: data,
});

export const deleteProvider = (number) => {
  const url = `${API_URL}/api/providers`;

  console.log(number);

  return (dispatch) => {
    let outputData = {};
    let params = {
      data: {
        identifier: number,
      },
    };
    console.log(params);

    Axios.delete(url, params).then(({ data }) => {
      console.log(data);
      if (data.error) {
        outputData["status"] = "danger";
        outputData["message"] = "Error creating form";
        dispatch(setGetProvider(outputData));
      } else {
        outputData["status"] = data.status;
        outputData["message"] = data.message;
        outputData["data"] = data;
        dispatch(setGetProvider(outputData));
      }
    });
  };
};

export const setDeleteProvider = (data) => ({
  type: types.deleteProvider,
  payload: data,
});

export const getCategories = () => {
  const url = `${API_URL}/api/categories`;

  return (dispatch) => {
    let outputData = {};

    Axios.get(url).then(({ data }) => {
      if (data.error) {
        outputData["status"] = "danger";
        outputData["message"] = "Error creating form";
        dispatch(setGetCategories(outputData));
      } else {
        outputData["data"] = data;
        outputData["message"] = data.message;
        dispatch(setGetCategories(outputData));
      }
    });
  };
};

export const setGetCategories = (data) => ({
  type: types.getCategories,
  payload: data,
});

export const getStats = () => {
  const url = `${API_URL}/api/providers/stats`;

  return (dispatch) => {
    let outputData = {};

    Axios.get(url).then(({ data }) => {
      if (data.error) {
        outputData["status"] = "danger";
        outputData["message"] = "Error creating form";
        dispatch(setGetStats(outputData));
      } else {
        outputData["data"] = data.data;
        outputData["message"] = data.message;
        dispatch(setGetStats(outputData));
      }
    });
  };
};

export const setGetStats = (data) => ({
  type: types.getStats,
  payload: data,
});
