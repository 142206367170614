import React, { useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import { Input, Label } from "reactstrap";
import { labelMapping } from "../../utils/constants";

const InputCheckControlled = ({ name, rules }) => {
  const {
    control,
    setValue,
    formState: { defaultValues },
  } = useFormContext();
  const { field } = useController({ name, control, rules });
  const value = field?.value?.value ?? {};
  const textLabel = name.split(".").pop();

  useEffect(() => {
    if (!field.value) {
      setValue(name, { value: { checked: false, obs: "" } });
    }
  }, [defaultValues]);

  if (!field.value) {
    return null;
  }
  return (
    <div className="row">
      <div className="col-6">
        <Label>{labelMapping[textLabel]}</Label>
      </div>
      <div className="col-1">
        <Input
          checked={value?.checked ?? false}
          onChange={(e) => {
            field.onChange({
              ...field?.value,
              value: { ...value, checked: e.target.checked },
            });
          }}
          type="checkbox"
        />
      </div>
      <div className="col-5">
        <Input
          maxLength="55"
          value={value?.obs ?? ""}
          onChange={(e) => {
            field.onChange({
              ...field?.value,
              value: { ...value, obs: e.target?.value.toUpperCase() },
            });
          }}
        />
      </div>
    </div>
  );
};

export default InputCheckControlled;
